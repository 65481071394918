import React from 'react'

const AboutThisItem = ({inthebox}) => {
  return (
 <>
 <div style={{borderBottom:"3px solid #eee" ,marginBottom: "10px"}} className='py-3'>
<div className='container' >
  <h5 style={{fontSize:"20px" ,color:"#212529"}}>What is in the box?</h5>
  <ul>
    {inthebox.map((data,ind)=>{
      return(<li style={{fontSize:"14px" ,color:"#212529"}} id={ind}>
            {data}
        </li>
     );
    })}
     </ul>
</div>
</div>
 </>
  )
}

export default AboutThisItem