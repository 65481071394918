import React from 'react'
import { homedata } from '../Data/homedata';
import { useNavigate } from 'react-router-dom';

const MightLike = () => {
  const navigate = useNavigate();
const handleButtonClick = (id) => {
    navigate(`/detail/${id}`);
  };
  
  return (
    <>
    <div style={{borderBottom:"3px solid #eee" }} className='py-3 pb-1'>
    <div className='container'>
      <h5 className='mb-1' style={{fontSize:"20px" ,color:"#212529"}}>You might also like</h5>
        <p style={{color:"#757575",fontSize:"14px ",fontWeight:"500",letterSpacing:"0.5px"}} className='mb-4'>Sponsored</p>

            <div className='out-of-loop-div m-0 p-0'>
            {homedata.map((card, id)=>{
                return(<>
                    <div className='cardLoopProduct m-0 p-0'> 
                    <div className=' m-0 p-0'>
                        <img src={card.badge}  className='m-0 p-0' style={{maxWidth:"110px"}} />
                    </div>
                    <div className='productImageInnr m-0 p-0'>
                        <img src={card.imgSrc}  onClick={() => handleButtonClick(card.id)}/>
                    </div>
                <div className='m-0 p-0 pe-3' style={{fontWeight:"500"}}>
            <div onClick={() => handleButtonClick(card.id)} className='title'
            style={{color:"#007185",fontSize:"14px",display:"inline-block",display: "-webkit-box" ,overflow: "hidden"}}> 
    					{card.title}
                  </div>
                </div>
                <div style={{display:"flex",alignItems:"center",margin:"3px 0 2px"}}>
                {card.starRating.map((img,data)=>{
                  return(   <img src={img}  className='star' />);
                    })}
                </div>

                <div style={{margin:"5px 0"}}>
                    <p style={{textAlign:"left",fontSize:"12px",color:"#555",fontWeight:"400",margin:"0"}}>{card.ratingBy} Reviews</p>
                </div>
                <div style={{display:"flex",flexWrap:"wrap",margin:"3px 0 0 4px"}}>
                    <h4 style={{margin:"0", marginRight:"10px",fontSize:"18px",fontWeight:"400",color:"#d32f2f"}}>-{card.discount}</h4>
                    <h4 style={{marginBottom:"2px",fontSize:"18px",fontWeight:"500",color:"#000",display:"flex", alignItems:"flex-start"}}>
                     <span style={{fontSize:"16px",display:"inline-block"}}>₹</span> {card.price}
                    </h4>
                </div>
                <div style={{color:"#555",display:"flex",fontSize:"13px"}}>
                    <span>List Price: </span>
                    <span style={{textDecoration:"line-through", marginLeft:"2px"}}>{card.originalPrice}</span>
                </div>
            </div>       
            </>
           ) })}
          
         </div>

      </div>
      </div>

    </>
  )
}

export default MightLike