import React from 'react'

const Review = ({review}) => {
  return (
    <>
    
{/* ======================review======================== */}
<div>
      <h5 className='mb-3' style={{fontSize:"20px" ,color:"#212529"}}>Customer Reviews</h5>
{(review.length!==0)?( 
review.map((data,ind)=>{
  return(<>
                  <div id={ind} className='reviewLoopInner mb-3 pb-3' style={{borderBottom:"2px solid #eeee"}}>
                  <div className='d-flex align-items-center'>
                    <div className='reviewImage'>
                    <img src="/assets/HomeImages/dummy-avatar.jpg"
                    style={{width:"50px", height:"50px" ,objectFit:"cover" ,borderRadius: "50%"}}></img>
                    </div>
                <h6 style={{marginLeft:"15px" ,marginBottom:"0" ,overflow: "hidden" ,fontSize:"14px"}}>{data?.reviewerName}</h6>
                  </div>
                  <div style={{display:"flex" ,alignItems:"center" ,margin:"3px 0 2px"}}>

      {data.reviewstar?.map((img,ind)=>{
   return(   <img src={img} id={ind}  className='star' />);
      })}
      <span style={{fontSize:"12px" ,color:"#c45500" ,fontWeight:"600" ,paddingLeft:"4px"}}>{data?.varfied}</span>
                  </div>
                <h6 className='mb-1' style={{fontSize:"15px"}}>
               {data?.review} </h6>
                <p className='m-0 ' style={{color:"#757575" , fontSize:"13px"}}>{data?.reviewDate}</p>
                 

              <div className='mainListImg '>
       {data.reviewImage?.map((img,ind)=>{
           return(   <div className='reviewImageList' id={ind}>
                <img src={img} width="100%" height="200px"/>
           </div>);
          })}
              </div>
              </div>
  </>)
})):(<><h5>No Record found</h5>
</>
)}
</div>
    </>
  )
}

export default Review