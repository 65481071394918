import React from 'react';
import '../styles/Detail.css';

const ProductCard = ({ varity }) => {
  const firstObject = varity[0]; // Output the first object's imgVar
  return (
    <>
      {/*============== first carousel of products====================*/}
      <div className='pt-3 moreproducts' style={{ borderBottom: "3px solid #eee" }}>
        <h3 className='mb-3' style={{ fontSize: "16px" }}>Color: <b>{firstObject.titleVar}</b></h3>

        <div className='d-flex productcard'>
          {varity?.map((data, ind) => {
            return (
              <div className="card cardscroll" style={{ width: "40%" }} id={ind} key={ind}>
                <img className="card-img-top" src={data.imgVar || '/default-image.jpg'} width="100%" alt="Card image cap" />

                <div className="card-body m-0 p-2">
                  <h3 style={{ fontSize: "14px", fontWeight: "600" }} className="card-title m-0 mb-1">
                    {data.titleVar || ''}
                  </h3>
                  <h4 className='d-flex align-content-start m-0 mb-1' style={{ fontSize: "18px", fontWeight: "500", color: "#0f1111" }}>
                    <span style={{ fontSize: "14px", display: "inline-block", paddingTop: "3px" }}>{(data.price>0)?"₹": ""}</span>{data.price || ''}
                  </h4>

                  {data.ogPrice && (
                    <p className="m-0 mb-1" style={{ fontSize: "13px", textDecoration: "line-through", color: "#555" }}>₹{data.ogPrice}</p>
                  )}
                  <p className='m-0 mb-1' style={{ fontSize: "13px", color: data.stock === 'In stock' ? "#008a00" : "red" ,paddingRight:"10px"}}>
                    {data.stock || `Currently unavailable`}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProductCard;