import React from 'react'
import { useNavigate } from 'react-router-dom'
const Nav2 = () => {
    const navigate =useNavigate();

    const handlesubmit=()=>{
        navigate("/");
    }
  return (
    <nav className="navbar bg-dark">
    <div className="container d-flex justify-content-start align-items-center">
      {/* <!-- Logo in the Center --> */}
      <button className="navbar-brand" onClick={handlesubmit}
       style={{ textAlign: "center" , border:"none",background:"transparent" }}>
        <img src="/assets/productsimage/logo.png" alt="Amazon" width="100" />
      </button>
    </div>
  </nav>
  )
}

export default Nav2
