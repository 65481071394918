import React from 'react'

const footer = () => {
  return (
    <div className='container m-0  mb-0 bg-black '>
    <div className='row pt-3 me-4 ms-4 text-center'>
      <div className='col-4 m-0 p-0'>
        <p style={{color:"#cccccc", cursor:"pointer", fontSize:"11px"}}>Conditions of Use</p>
      </div>

      <div className='col-4 m-0 p-0 text-center'>
        <p style={{color:"#cccccc", cursor:"pointer",fontSize:"11px"}}>Privacy Notice</p>
      </div>

      <div className='col-4 m-0 p-0 text-center'>
        <p style={{color:"#cccccc", cursor:"pointer",fontSize:"11px"}}>Interest Based Ads</p>
      </div>
    </div>
 

  <p className='text-center m-0 pb-4' style={{color:"#cccccc",fontSize:"11px"}} >&copy; 1996-2022. Amazon.com, inc. or its affiliates</p>

  </div>
  )
}

export default footer
