import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { homedata } from "../Data/homedata";
import "../styles/Global.css";  
import { FaHeart } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import Nav3 from "../Headerfooter/Nav3";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../styles/Detail.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,Pagination } from 'swiper/modules';
import Card from '../Components/Card';
import ProductCard from '../Components/ProductCard';
import MightLike from '../Components/MightLike';
import Review from '../Components/Review';
import AboutThisItem from '../Components/AboutThisItem';
import Gallery from '../Components/Gallery';

const CardDetail = () => {
  const { id } = useParams();
  const card = homedata.find((item) => item.id === parseInt(id));
  const navigate = useNavigate();

    const [selectedValue, setSelectedValue] = useState(1);
  
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  //======================
// const [timeLeft, setTimeLeft] = useState((16 * 60)+1); // 30 minutes in seconds

// useEffect(() => {
//   const timer = setInterval(() => {
//     setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
//   }, 1000); // Update every second

//   // Clean up the interval on component unmount
//   return () => clearInterval(timer);
// }, []);

// const formatTime = (time) => {
//   const minutes = Math.floor(time / 60);
//   const seconds = time % 60;
//   return `${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`;
// };

//==================


  if (!card) {
     return <div>Product Not Found</div>;
  }

  // Handle submit on button click
  const handleSubmit = (e) => {
    e.preventDefault();
    const ogprice = {
      price: card.price,
    };
    navigate("/check-out", { state: { ogprice } });
  };

  return (
    <>
      <Nav3/>     
    <div className="container-fluid p-1 m-0 pb-5 ">
    <div className='container-fluid product-slider'>
      <div className='offer-badge'>{card.discount} <br/> Off</div>
      <div className="love-icon"></div>
      <div className="share-icon"></div>
<Swiper
  spaceBetween={30}
  pagination={{
    clickable: true,
  }}
  autoplay={{
    delay: 2000,
  }}
  modules={[Autoplay, Pagination]}
  className="mySwiper"
>
  {card.imgsrcs.map((src, index) => (
    <SwiperSlide key={index}>
      <img src={src} alt={`Product ${index + 1}`} className="w-100 pt-4 mt-3" />
    </SwiperSlide>
  ))}
</Swiper>


    </div>

            {/* Title and MRP Section */}
        <div className=' p-0 m-0 pe-2 ps-2'>
          <div className="col-12 col-lg-6  detail-title m-0 p-0">
            <p  className='sold'>{card.sold}</p>
          
          <div className='ratings'>
 <span style={{fontSize: "13px" ,color:"#252525" ,paddingRight:"4px" }}>{card.rating}</span>
    {card.starRating.map((img,data)=>{
   return(   <img src={img}  className='star' />);
})}

      <span style={{fontSize: "13px" ,color:"#007c8a" ,paddingLeft:"4px" }}>{card.ratingBy}</span>
           </div>



<div className='product-title '>{card.title}</div>
<ProductCard varity={card.varity}/>



 {/*============== Information====================*/}
  <div style={{borderBottom:"3px solid #eee" }} className='py-3 pb-1'>
    <div className='container m-0  mb-2'>
  <div style={{display:"flex" , flexWrap:"wrap"}}> 
       <h4 style={{fontSize:"30px" ,fontWeight:"400" ,color:"#d32f2f" ,marginRight:"10px"}}>
        <span>-</span>
          {card.discount}
              </h4>
              <h4 className="" style={{fontSize:"30px" ,fontWeight:"500" ,color:"#000" ,
                marginRight:"10px",display:"flex",alignItems:"flex-start"
                }}>
             <span style={{fontSize:"16px",display:"inline-block",paddingTop:"3px"}}>{"  ₹ " }</span>
           {card.price}
             </h4>
           
       </div>

              <div style={{color:"#555",display:"flex",fontSize:"13px"}}>
                <span >M.R.P. :</span> ₹
                <span style={{textDecoration:"line-through"}}>{card.originalPrice}</span>
              </div>

              <div style={{marginTop:"5px"}}>
                <img src="/assets/HomeImages/filled.png"  style={{maxWidth:"80px"}}/>
              </div>
                <div>
                  <p style={{fontSize:"15px",color:"#252525"}}>Inclusive of all taxes</p>
                </div>
      </div>
   </div>
  
   <div style={{borderBottom:"3px solid #eee" }} className='py-3 '>
    <div className='container'>
        <div className='d-flex align-items-center'>
              <img src="/assets/HomeImages/savings.svg" width="28px" />
              <span className='d-inline-block ms-2'> All offers & discounts </span>
        </div>
    </div>
  </div>

  
   <div style={{borderBottom:"3px solid #eee" }} className='py-3 '>
    <div className='container'>
        <div className='d-flex align-items-center'>
              <img src="/assets/HomeImages/extra.png" width="28px" />
           <div className='ms-2'>
            <h4 style={{fontSize: "17px",marginBottom: "3px"}}>Extended Warranty </h4>
            <span style={{fontSize: "13px" ,color: "#757575"}}> Claims support through Amazon </span>
           </div>  
        </div>
    </div>
  </div>


  <div className='container pt-3'>
    <h4 style={{fontSize: "17px",fontWeight:"600"}}>
    Total Price: ₹
    <span style={{fontSize:"19px"}}>  {Math.round(card.price * selectedValue)}</span>
    </h4>
    <span className='d-flex mt-2'> 
       <span style={{color:"#007186"}}>Free Delivery </span>
       <span className="d-inline-block ps-1"> Friday,10 September</span>
    </span>
    <p style={{color:"green"}}>In Stock</p>

      <div className='mainSelectOptionShw'> 
    <select value={selectedValue} onChange={handleChange} className='optionSelected' >
      <option  value="1" >1</option>
      <option  value="2" >2</option>
      <option  value="3" >3</option>
      <option  value="4" >4</option>
      <option  value="5" >5</option>
    </select>
    </div>

  </div>
           
          <div>
{/* button */}
      <div className='py-3 m-2 mt-0 pt-1'>
               <div className='col-12 mt-4'>
                      <button      onClick={handleSubmit}
                   className="buttoncommondetail text-center w-100 d-block "
                      style={{background:"#ffd814" , borderRadius:"25px",color:"black"}}
                      type="submit" value="send">Add to Cart</button>
                </div>
               <div className='col-12 mt-3'>
                      <button      onClick={handleSubmit}
                   className="buttoncommondetail text-center w-100 d-block "
                      style={{background:"orange" , borderRadius:"25px",color:"black"}}
                      type="submit" value="send">Buy Now</button>
               </div>
                    
      </div>


{/* ================placements icon================== */}
    <Card/>
      <MightLike/>
</div>

       {/* About this Item Section */}
       
{/* ======================Features & Details===================== */}
<div style={{borderBottom:"3px solid #eee" }} className='py-3 pb-1'>
    <div className='container'>
      <h5 style={{fontSize:"20px" ,color:"#212529"}}>Features & Details</h5>
      <ul className="">
              {card.fetures.map((desc, id) => (
                <li key={id}>{desc}</li>
              ))}
            </ul>   
      </div>
      </div>


{/* ======================Specifications===================== */}
<div style={{borderBottom:"3px solid #eee" }} className='py-3'>
    <div className='container'>
      <h5 style={{fontSize:"20px" ,color:"#212529"}}>Specifications</h5>
 {/* Features */}
 <div className=' m-0 p-0 mt-2 '>
          <table className='p-0 m-0 tableclass'>
      <tbody style={{border:"0.5px solid #e6e9ec"}}>
        {card.productDetails.map((detail, index) => (
          <tr key={index} className=''>
            <td style={{border:"0.5px solid #e6e9ec"}} className='p-2 pb-2 pt-1 pe-0 tabletr'><span><strong>{detail.label}</strong></span></td>
            <td style={{border:"0.5px solid #e6e9ec"}} className='p-2 pb-2 pe-0 pt-1 tabletd'><span>{detail.value}</span></td>
          </tr>
        ))}
      </tbody>
    </table>
          </div>
     </div>
</div>


{/* ======================Description===================== */}
<div style={{borderBottom:"3px solid #eee" }} className='py-3'>
    <div className='container'>
      <h5 style={{fontSize:"20px" ,color:"#212529"}}>Description</h5>
      <p className='mb-0' style={{fontSize:"14px" ,lineHeight:"1.55",letterSpacing:"0.5px"}}>
{card.description}</p>              </div>
</div>

{/* ======================Gallery===================== */}
<Gallery gallery={card.gallery}/>

{/* ================about this items=============== */}

<AboutThisItem inthebox={card.inthebox}/>

{/* ===============reviews start========== */}
    <Review review={card.customerReview}/>


            {/* Button Section */}
              <div className="m-0 p-0 fixed-bottom mb-1">
              <div className="row m-0 p-0">
                <div className="col-6 m-0 p-0">
                  <button onClick={handleSubmit}
                  className="buttoncommondetail text-center w-100 d-block "
                  style={{color:"#000" , background:"#ffd814"}}
                  >
                    Add To Cart
                  </button>
                </div>
                <div className="col-6 p-0 m-0">
                 <button
                    onClick={handleSubmit}
                    className="buttoncommondetail text-center w-100 d-block "
                 
                  >
                    Buy Now
                  </button>          
                </div>          
              </div>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default CardDetail;
